const createProgressData = (progressData = []) => {
	const progressDataCopy = global.structuredClone(progressData);

	let total = { name: 'TOTAL' };

	// Create total row with all the key values set to 0
	progressDataCopy.forEach((data) => {
		delete data.name
		Object.keys(data).forEach((key) => { total[key] = 0 });
	});

	// Sum all the key values for storing them in total row
	progressDataCopy.forEach((data) => {
		Object.keys(data).forEach((key) => {
			total[key] += data[key];
		})
	});

	// Calculate average score for total row
	if (total.average_score) {
		total.average_score /= progressDataCopy.length;
	}

	progressData.push(total);

	return progressData;
}

export default createProgressData;
